import React from "react";

const Land = () => {
  return (
    <div className="w-full bg-gradient-to-b from-notverydark to-black land1:bg-gradient-to-r from-notverydark via-black to-black relative overflow-hidden lg:h-[700px] land1:h-[650px] h-[1100px]">
      <section>
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 items-start relative z-0">
     
          <div className="flex flex-col justify-center">
            <img
              className="w-[350px] mx-auto mt-12 mb-5 ml-2 "
              src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fplus_w.png?alt=media&token=e592cc00-365a-4f71-824c-178d2aa237cb"
              alt="Sripay logo"
            />
            <h1 className="md:text-2xl sm:text-3xl text-2xl text-white font-bold py-2 px-4 font-custom ">
              Seamless Payments, Global Connections
            </h1>
            <p className="md:text-xl sm:text-xl text-lg text-white font-light py-2 px-4 font-custom ">
              Your trusted payment gateway in Sri Lanka. Make payments
              seamlessly with Alipay, WeChat Pay, Visa, Master Card, and many
              more.
            </p>
          
            <a 
  href="https://dashboard.sripay.lk/auth/register" 
  className="flex justify-center"
>
  <button className="bg-white hover:bg-dark-blue hover:text-white duration-500 w-[325px] rounded-full text-2xl text-light-blue font-semibold font-custom my-10 mx-auto items-center py-4">
    Register as a Merchant
  </button>
</a>


    
          </div>
          <video
              autoPlay
              loop
              muted
              playsInline
              className="w-[500px] mx-auto my-8 object-cover  'opacity-100' : 'opacity-0'"
            >
              <source
                src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FAnimation%2FImg%208010.mp4?alt=media&token=26ac7b77-70a2-4636-967d-2be6dea2c88b"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
        </div>

        {/* Bottom shape image */}
        <div className="absolute bottom-0 left-0 w-full h-auto z-10">
          <img
            className="w-full h-auto object-cover"
            src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fbar.png?alt=media&token=bfcd2f0d-5b81-41de-888b-2ca9c382465b"
            alt="Bottom shape"
          />
        </div>
      </section>
    </div>
  );
};

export default Land;