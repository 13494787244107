import React from "react";
import { motion } from "framer-motion";

const Preloader = () => {
  const pathLength1 = 600; // Approximate length of the first path
  const pathLength2 = 450; // Approximate length of the second path
  const animationDuration = 3; // Total duration of stroke and fill animations (2 + 1)

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{
        delay: animationDuration + 1.5, // Total delay to match scale and shape animations
        duration: 1, // Duration for the fade-out of the entire preloader
        ease: "easeInOut",
      }}
      className="fixed inset-0 flex items-center justify-center bg-white"
    >
      <motion.div
        initial={{ scale: 1, opacity: 1 }}
        animate={{ scale: [1, 0.9, 6], opacity: [1, 1, 0] }}
        transition={{
          delay: animationDuration, // Delay for shape animations before fade-out starts
          duration: 1.5,
          ease: "easeInOut",
          times: [0, 0.5, 1],
        }}
        className="flex items-center justify-center"
      >
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 300 300"
          className="w-full h-full max-w-screen max-h-screen"
          preserveAspectRatio="xMidYMid meet"
        >
          {/* First path with stroke and fill animations */}
          <motion.path
            d="M146.619 123H222.143L227.302 123.21L230.603 124.053L232.873 125.316L234.937 127.21L236.175 129.316L237 132.053V134.579L235.968 139.421L232.667 152.263L230.19 162.158L228.952 165.526L227.508 167.842L225.651 169.947L223.587 171.842L220.492 173.737L217.19 175L214.302 175.631L212.857 175.842H172.619L172 178.158L169.317 189.737L165.81 205.105L163.952 212.895L162.714 216.053L161.063 218.579L158.794 221.105L156.73 222.789L153.841 224.474L151.159 225.526L148.063 226.158L138.778 226.579L125.365 227H115.873L113.19 226.158L110.921 224.895L109.889 224.053L108.238 221.737L107.413 219.842L107 218.158V215.21L107.825 211L111.746 194.158L115.873 176.053L118.349 165.526L122.683 146.579L124.746 137.737L125.984 134.368L127.429 132.053L129.286 129.737L131.556 127.631L134.238 125.947L136.714 124.684L139.81 123.631L142.079 123.21L146.619 123Z"
            initial={{
              strokeDasharray: pathLength1,
              strokeDashoffset: pathLength1,
              fill: "#040562",
              fillOpacity: 0,
            }}
            animate={{ strokeDashoffset: 0, fillOpacity: 1 }}
            transition={{
              strokeDashoffset: { duration: 2, ease: "easeInOut" },
              fillOpacity: { delay: 2, duration: 1, ease: "easeInOut" },
            }}
            style={{ stroke: "#040562", strokeWidth: 2 }}
          />

          {/* Second path with stroke and fill animations */}
          <motion.path
            d="M150.263 72H168.78L182.153 72.2116L184.828 73.0581L186.885 74.3278L188.737 76.2324L190.177 78.7718L191 81.5228V83.2158L189.971 88.5062L188.737 94.0083L186.679 102.473L183.182 117.286L180.096 130.618L176.392 145.643L173.1 159.398L171.866 163.207L170.426 165.747L168.78 167.863L166.928 169.768L164.048 171.672L161.579 172.942L159.11 173.788L157.876 174H81.7512L70.8469 173.788L68.1722 172.942L65.7033 171.461L64.0574 169.768L62.6172 167.44L62 165.324V163.207L63.4402 156.647L66.5263 144.585L69.201 134.427L70.2297 131.676L71.6699 129.56L73.5215 127.444L75.9904 125.328L78.8708 123.635L82.5742 122.365L85.866 121.942H126.809L127.22 119.614L129.483 110.091L133.392 93.3734L135.45 84.9087L136.478 82.1577L137.713 80.0415L139.359 77.9253L141.211 76.0207L143.885 74.1162L147.177 72.6349L150.263 72Z"
            initial={{
              strokeDasharray: pathLength2,
              strokeDashoffset: pathLength2,
              fill: "#3EC7E4",
              fillOpacity: 0,
            }}
            animate={{ strokeDashoffset: 0, fillOpacity: 1 }}
            transition={{
              strokeDashoffset: { duration: 2, ease: "easeInOut" },
              fillOpacity: { delay: 2, duration: 1, ease: "easeInOut" },
            }}
            style={{ stroke: "#3EC7E4", strokeWidth: 2 }}
          />
        </motion.svg>
      </motion.div>
    </motion.div>
  );
};

export default Preloader;
