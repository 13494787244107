import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Land4 = () => {
  // Custom Arrow components
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="custom-arrow custom-next absolute right-[-30px] sm:right-[-50px] top-1/2 transform -translate-y-1/2 z-10"
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className="text-gray-400 text-4xl"
        />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="custom-arrow custom-prev absolute left-[-30px] sm:left-[-50px] top-1/2 transform -translate-y-1/2 z-10"
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-gray-400 text-4xl"
        />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    centerMode: true, // This ensures the carousel is centered
    centerPadding: "0px", // Removes any padding on the sides
    nextArrow: <NextArrow />, // Custom Next Arrow
    prevArrow: <PrevArrow />, // Custom Prev Arrow
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px", // Remove space between logos
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          centerPadding: "0px", // Remove space between logos
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          centerPadding: "0px", // Remove space between logos
        },
      },
    ],
  };

  const logos = [
    "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fbasilur_tea_1x.png?alt=media&token=ae5a73b8-d3dd-4977-b1e3-79eff41af519",
    "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fcrocadile_1x.png?alt=media&token=b6c458dd-996f-4bdb-8534-ec72f067a59a",
    "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fhav_dental_1x.png?alt=media&token=80123dfa-eecd-48cb-8c85-25eac8ec07f6",
    "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fjetwin_tr_1x.png?alt=media&token=544d439b-e8f4-4cd8-a0d4-bdeab72db165",
    "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Flaksala_1x.png?alt=media&token=932d035a-af0f-403c-971c-c918092f770b",
    "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fmlesna_1x.png?alt=media&token=58262281-cd67-4fa5-9f43-5ba3e1a81d4a",
    "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fpremadasa_1x.png?alt=media&token=31d3433f-1976-412d-a368-7ba35820af99",
    "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Ftea_avenew_1x.png?alt=media&token=460d83d9-7330-47c2-b034-5671b8f98616",
    "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fuptown_1x.png?alt=media&token=867fc55a-a396-49e0-9417-c898e1076a40",
  ];

  const handlePlayClick = () => {
    // Redirect to the desired YouTube URL
    window.open("https://www.youtube.com/watch?v=7cIP0NDv-0s", "_blank");
  };

  return (
    <div className="bg-white">
      <div className="flex flex-col-reverse md:flex-row items-center md:items-center px-6 space-y-6 md:space-y-0 md:space-x-8 py-6 md:pt-20">
        {/* Image Section - Left on Desktop */}
        <div className="relative w-full md:w-1/2 flex justify-center items-center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fpayment_gateway_woman.png?alt=media&token=4fd87a67-b647-4f46-8f43-77394e157dca"
            alt="SriPay Payment"
            className="w-full h-auto rounded-t-large rounded-bl-large rounded-br-extra-large mt-8 md:mt-0 shadow-lg md:w-[500px]"
          />
          <div className="absolute inset-0 flex justify-center items-center">
            <button
              className="bg-white text-notverydark p-4 rounded-full shadow-md hover:bg-gray-100"
              onClick={handlePlayClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-10 h-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14.752 11.168l-6.585-3.416A1 1 0 007 8.671v6.658a1 1 0 001.167.997l6.585-3.416a1 1 0 000-1.742z"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Text Section - Right on Desktop */}
        <div className="flex-1 flex flex-col justify-center items-center text-center  weare:items-start weare:text-start h-full weare:pr-52">
          <h2 className="text-4xl lg:text-4xl md:text-2xl font-bold font-custom text-gray-500 mb-4">
            SriPay Payment Gateway
          </h2>
          <p className="text-lg lg:text-lg md:text-base font-custom font-normal text-gray-500">
            SriPay – We Provide Payment Gateway Services to Sri Lanka that
            Include Visa (mVisa), Alipay, WeChat Pay, Union Pay, and Master
            (Master Pass). Our technology caters to the Online and Offline
            Shops, Hotels, Restaurants etc.
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:items-center px-6 space-y-6 md:space-y-0 md:space-x-8 py-6 md:py-5">
        {/* Text Section - Top in Mobile and Left on Desktop */}
        <div className="flex-1 flex flex-col justify-center items-center text-center weare:items-end weare:text-end h-full weare:pl-52">
          <p className="text-lg lg:text-lg md:text-base font-custom font-normal text-gray-500">
            Transforming shopping experiences in Sri Lanka{" "}
          </p>
          <h2 className="text-4xl lg:text-4xl md:text-2xl font-bold font-custom text-gray-500 mb-4 mt-4">
            AliPay In-store payment across Sri Lanka
          </h2>
          <p className="text-lg lg:text-lg md:text-base font-custom font-normal text-gray-500">
            Are Chinese customers frequenting your business in Sri Lanka? If
            you're a local merchant looking to cater to this growing
            demographic, we've got a solution for you.
          </p>
        </div>

        {/* Image Section - Bottom in Mobile and Right on Desktop */}
        <div className="relative w-full md:w-1/2 flex justify-center items-center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fchinese_girls.png?alt=media&token=d88b2ccd-4ac5-4b23-ba28-d2312cb88fe9"
            alt="SriPay Payment"
            className="w-full h-auto rounded-t-large rounded-br-large rounded-bl-extra-large mt-8 md:mt-0 shadow-lg md:w-[500px]"
          />
        </div>
      </div>

      <div className="flex flex-col-reverse md:flex-row items-center px-6 py-6 md:space-x-8 md:pt-20">
        {/* Image Section - Left on Desktop, Bottom on Mobile */}
        <div className="relative w-full md:w-1/2 flex justify-center items-center mt-6 md:mt-0">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fchinese_tourists.png?alt=media&token=fe748012-e610-4258-b376-dbb9c23e12f4"
            alt="SriPay Payment"
            className="w-full h-auto rounded-t-large rounded-bl-large rounded-br-extra-large shadow-lg md:w-[500px]"
          />
        </div>

        {/* Icons and Text Section - Right on Desktop, Top on Mobile */}
        <div className="flex flex-col items-center text-center md:text-start md:items-start md:w-1/2 space-y-4">
          <div className="flex items-center space-x-2">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FSearch.png?alt=media&token=3d31f56b-74e8-49f4-b4bc-47f83ce711ac"
              alt="Expand Icon"
              className="w-10 h-10 lg:w-10 lg:h-10 md:w-6 md:h-6" /* Adjust the width and height as needed */
            />
            <h2 className="text-4xl lg:text-4xl md:text-xl font-bold text-gray-500">
              Expand Your Reach
            </h2>
          </div>
          <p className="text-lg lg:text-lg md:text-base font-normal text-gray-500">
            Capture a broader audience! Alipay In-Store Payments transcend
            borders, attracting Chinese customers and amplifying your sales
            potential across Sri Lanka.
          </p>
          <div className="flex items-center space-x-2 mt-4">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FCombo_Chart.png?alt=media&token=f177f5fe-30c0-4bda-9ade-2c96e6e0840a"
              alt="Boost Sales Icon"
              className="w-10 h-10 lg:w-10 lg:h-10 md:w-6 md:h-6" /* Adjust the width and height as needed */
            />
            <h2 className="text-4xl lg:text-4xl md:text-xl font-bold text-gray-500">
              Boost Sales Effortlessly
            </h2>
          </div>
          <p className="text-lg lg:text-lg md:text-base font-normal text-gray-500">
            Seamless transactions mean more sales! Alipay In-Store Payments
            simplify the purchase process, enhancing customer satisfaction and
            driving revenue growth for Sri Lankan merchants.
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center py-10 ">
        <h2 className="text-5xl font-custom font-light text-light-blue uppercase tracking-wide">
          Our{" "}
          <span className="text-notverydark font-bold font-custom">
            Services
          </span>
        </h2>
        <p className="mt-2 text-gray-500 text-center font-custom">
          Providing timely services to Individuals, Merchants and Banks /
          Finance Companies
        </p>
      </div>

      {/* Blue container for desktop view */}
      <div className="bg-notverydark py-8 lg:py-8 lg:justify-center lg:rounded-tl-extra-large lg:rounded-bl-2xl land4:ml-56">
        <div className="container mx-auto p-5 w-full">
          <div className="max-w-6xl mx-auto grid grid-cols-1 gap-10 px-4 md:grid-cols-3 lg:py-0 lg:pl-11">
            {/* Card 1 */}
            <div className="bg-white rounded-3xl shadow-lg max-w-sm mx-auto md:max-w-none w-full sm:w-full lg:max-w-none lg:mx-0">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fcard3.png?alt=media&token=7faa2bf5-620a-4978-a950-f5b25d161ce7"
                alt="Merchant Solutions"
                className="w-full h-56 lg:h-56 md:h-40 object-cover rounded-t-3xl"
              />
              <div className="px-6 pb-6 lg:px-6 lg:pb-6 md:px-2 md:pb-2">
                <h2 className="text-xl lg:text-2xl md:text-lg font-bold font-custom mt-1 text-center">
                  Merchant Solutions
                </h2>
                <p className="mt-3 text-xs text-gray-600 font-light font-custom text-center">
                  Sripay Merchant Solutions redefine payment ease. Register for
                  free and enjoy the flexibility to accept payments from both
                  international and local customers, including Visa/Mastercard
                  and popular local/global payment apps. Explore our suite of
                  tools, including the SriPay Merchant app, Multi-functional
                  static QR, Android POS machine, Cash registry, Online POS, and
                  Mobile POS App.
                </p>
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-white rounded-3xl shadow-lg max-w-sm mx-auto md:max-w-none w-full sm:w-full lg:max-w-none lg:mx-0">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fcard2.png?alt=media&token=6edbad43-b8ba-40cc-a544-8e534c4889e4"
                alt="Merchant Solutions"
                className="w-full h-56 lg:h-56 md:h-40 object-cover rounded-t-3xl"
              />
              <div className="px-6 pb-6 lg:px-6 lg:pb-6 md:px-2 md:pb-2">
                <h2 className="text-xl lg:text-2xl md:text-xl font-bold font-custom mt-1 text-center">
                  Life Style Solutions
                </h2>
                <p className="mt-3 text-xs text-gray-600 font-light font-custom text-center">
                  SriPay is more than a payment platform, it's a lifestyle
                  solution providing additional services. Seamlessly pay all
                  your bills (Electricity, Water, Telephone, Insurance etc),
                  effortlessly use Lanka QRs for payments, and conveniently book
                  hotels and restaurants. Access CityExpress for taxi and
                  delivery services, and explore CityMall for online shopping.
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="bg-white rounded-3xl shadow-lg max-w-sm mx-auto md:max-w-none w-full sm:w-full lg:max-w-none lg:mx-0">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fcard1.png?alt=media&token=542a114a-704a-42fe-9d1d-ffd5bec4bd55"
                alt="Merchant Solutions"
                className="w-full h-56 lg:h-56 md:h-40 object-cover rounded-t-3xl"
              />
              <div className="px-6 pb-6 lg:px-6 lg:pb-6 md:px-2 md:pb-2">
                <h2 className="text-xl lg:text-2xl md:text-lg font-bold font-custom mt-1 text-center">
                  Payment Gateway Solutions
                </h2>
                <p className="mt-3 text-xs text-gray-600 font-light font-custom text-center">
                  SriPay offers diverse payment options for your online store
                  through various embedded payment gateway solutions
                  consolidated into one plugin. The SriPay payment gateway is an
                  ideal choice for accepting payments, seamlessly supporting
                  Visa, Mastercard, and Alipay, ensuring a streamlined and
                  secure transaction experience for your customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center py-10 md:pt-20 ">
        <h2 className="text-5xl font-custom font-light text-light-blue text-center uppercase tracking-wide">
          Our{" "}
          <span className="text-notverydark font-bold font-custom">
            MERCHANTS
          </span>
        </h2>
        <p className="mt-4 text-gray-500 text-center w-5/6 md:w-5/6 font-custom">
          With numerous registered merchants, SriPay builds the connection
          between local merchants and consumers from China, expanding their
          customer demographic beyond the local market and giving insight to
          interested Chinese consumers on the versatile products and services
          offered in Sri Lanka.
        </p>
      </div>

      <div className="w-5/6 md:w-3/4 m-auto relative">
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index} className="px-1 ">
              <img
                src={logo}
                alt={`Logo ${index + 1}`}
                className="w-32 h-32 "
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Land4;
