import React from 'react';
import { motion } from 'framer-motion';
import Land from '../Component/Land';
import Land2 from '../Component/Land2';
import Header from "../Component/Header";
import Land3 from '../Component/Land3';
import Land4 from '../Component/Land4';
import Land5 from '../Component/Land5';
import Footer from '../Component/Footer';

const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className='w-full bg-white pt-16'
    >
      <div>
        <Header />
      </div>
      <div>
        <Land />
      </div>
      <div>
        <Land2 />
      </div>
      <div>
        <Land3 />
      </div>
      <div>
        <Land4 />
      </div>
      <div>
        <Land5 />
      </div>
      <div>
        <Footer />
      </div>
    </motion.div>
  );
};

export default Home;
