import React from "react";

const Land3 = () => {
  const imgStyle = "mx-auto mb-3 w-16 h-16";
  const h3style =
    "text-lg sm:text-xl lg:text-2xl font-semibold font-custom mb-2 py-2";
  const pstyle = "text-xs font-custom sm:text-sm lg:text-base font-light";

  const weimgStyle = "w-40 h-40 md:w-52 md:h-52 mb-0 md:mb-3";
  const weh3Style = "text-lg md:text-xl font-bold font-custom";
  const wepStyle =
    "mt-2 text-sm md:text-base font-custom font-light weare:mt-4 px-8";

  const features = [
    {
      icon: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FUs_Dollar_Circled.png?alt=media&token=28541660-4159-4421-9906-22d2c9f0288f",
      title: "USD Payments",
      description:
        "You can accept payments in both USD and LKR currencies and the settlements will be done by the relevant currency.",
    },
    {
      icon: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FLowest_Price.png?alt=media&token=674e0caf-cf07-4b8e-8e96-5058a8b411e8",
      title: "Lowest Rates Guaranteed",
      description:
        "We provide the lowest interest rates on settlements giving our customers maximum benefits.",
    },
    {
      icon: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FCoin_in_Hand.png?alt=media&token=30bde24f-a3cb-4031-8c41-78a64c3d2080",
      title: "Payment Gateway Provider",
      description:
        "Create a bridge between local merchants and foreigners as well as local customers.",
    },
    {
      icon: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FStreet_View.png?alt=media&token=2de01e8a-8cbd-4533-be24-61b4e501d506",
      title: "Individual Payment Links",
      description:
        "We provide QR facilities to individual merchants as well. You don’t have to represent an organization.",
    },
    {
      icon: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FTo_Do.png?alt=media&token=2ad9ab8a-09e4-46f0-8e25-82092279e13e",
      title: "Settlement Modes",
      description:
        "We provide Immediate, Same Day, and Next Day settlement options with different interest rates so instant completion of the transaction won’t be an issue.",
    },
    {
      icon: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FFoul.png?alt=media&token=3dba1b6e-f428-4096-a8c9-b39ade6a0f34",
      title: "Credit Card Cash Advance",
      description:
        "Cash advances against credit cards are available on SriPay.",
    },
  ];

  return (
    <div className="bg-notverydark text-white pt-8 lg:py-0 ">
      <h2 className="px-6 text-center text-2xl sm:text-4xl lg:text-5xl font-bold font-custom mb-6 lg:mb-10 lg:ml-10 md:text-left lg:px-8">
        Why You Should Choose
      </h2>
      <div className="flex justify-center pb-8 md:justify-start lg:ml-32">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fplus_w.png?alt=media&token=e592cc00-365a-4f71-824c-178d2aa237cb"
          alt="Sri Pay"
          className="w-60 -center lg:w-[300px] lg:h-46"
        />
      </div>
      <div className="grid grid-cols-2 grid-rows-2 gap-2 p-1 md:grid-cols-3 land3:grid-cols-4">
        {/* 1st row, 1st column */}
        <div className="col-span-1 row-span-1 bg-notverydark text-white p-4 sm:p-6 text-center order-1">
          <img
            src={features[0].icon}
            alt={features[0].title}
            className="mx-auto mb-3 w-20 h-16"
          />
          <h3 className={`${h3style}`}>{features[0].title}</h3>
          <p className={`${pstyle}`}>{features[0].description}</p>
        </div>

        {/* 1st row, 2nd column */}
        <div className="col-span-1 row-span-1 bg-notverydark text-white p-4 sm:p-6 text-center order-2 lg:order-3">
          <img
            src={features[1].icon}
            alt={features[1].title}
            className={`${imgStyle}`}
          />
          <h3 className={`${h3style}`}>{features[1].title}</h3>
          <p className={`${pstyle}`}>{features[1].description}</p>
        </div>

        {/* 1st row, 3rd column */}
        <div className="col-span-1 row-span-1 bg-notverydark text-white p-4 sm:p-6 text-center order-3 lg:order-2">
          <img
            src={features[2].icon}
            alt={features[2].title}
            className={`${imgStyle}`}
          />
          <h3 className={`${h3style}`}>{features[2].title}</h3>
          <p className={`${pstyle}`}>{features[2].description}</p>
        </div>

        {/* 1st row, 4th column */}
        <div className="col-span-1 row-span-1 bg-notverydark text-white p-4 sm:p-6  text-center order-4">
          <img
            src={features[3].icon}
            alt={features[3].title}
            className={`${imgStyle}`}
          />
          <h3 className={`${h3style}`}>{features[3].title}</h3>
          <p className={`${pstyle}`}>{features[3].description}</p>
        </div>

        {/* 2nd row, 1st column */}
        <div className="col-span-1 row-span-1 land3:col-span-2 bg-notverydark text-white p-4 sm:p-6 text-center order-5 land3:order-4 land3:pl-96 ">
          <img
            src={features[4].icon}
            alt={features[4].title}
            className={`${imgStyle}`}
          />
          <h3 className={`${h3style}`}>{features[4].title}</h3>
          <p className={`${pstyle}`}>{features[4].description}</p>
        </div>

        {/* 2nd row, 2nd column */}
        <div className="col-span-1 row-span-1 land3:col-span-2 bg-notverydark text-white p-4 sm:p-6  text-center order-6 land3:order-5 land3:pr-96 ">
          <img
            src={features[5].icon}
            alt={features[5].title}
            className={`${imgStyle}`}
          />
          <h3 className={`${h3style}`}>{features[5].title}</h3>
          <p className={`${pstyle}`}>{features[5].description}</p>
        </div>
      </div>

      <div className="relative w-full">
        {/* Container */}
        <div className="flex flex-col sm:flex-col-reverse">
          {/* Background Image */}
          <div className="relative w-full h-[300px] sm:h-[450px]">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FLand3_cover.png?alt=media&token=a7cadf75-cfce-4a2e-adc2-cbfd55e2cc44"
              alt="Payment Solutions"
              className="w-full h-full object-cover"
            />

            {/* Text Content for Desktop (Absolute Positioning) */}
            <div className="hidden sm:flex absolute inset-0 flex-col justify-center items-center text-center text-white lg:px-52 bg-opacity-40 bg-black">
              <div className="flex flex-col justify-center items-center h-full">
                <h1 className="text-3xl md:text-4xl font-bold mb-4 font-custom">
                  We provide payment solutions and payment-related software to
                  the world.
                </h1>
                <p className="text-xs md:text-sm font-light mt-4 font-custom">
                  Payment solutions include VISA, Master, Amex, JCB, AliPay,
                  WeChat pay, Union Pay, M-Pesa, and Crypto payments. Our Main
                  clients are Banks and Merchants. For banks and Financial
                  companies, we do have services such as Payment machines and
                  software, Wallet developments, and Card payment solutions
                  including Cards of Credit and debit cards. for the Merchants
                  we provide Payment acceptance services with a very minimal
                  fee. That includes all the main payment solutions in the
                  world.
                </p>
              </div>
            </div>
          </div>

          {/* Text Content for Mobile (Normal Positioning) */}
          <div className="flex sm:hidden flex-col justify-center items-center text-center text-black bg-opacity-50 ">
            <div className="bg-white px-8 py-5 text-normal-blue">
              <h1 className="text-2xl font-bold mb-4 font-custom">
                We provide payment solutions and payment-related software to the
                world.
              </h1>
              <p className="text-sm font-light font-custom">
                Payment solutions include VISA, Master, Amex, JCB, AliPay,
                WeChat pay, Union Pay, M-Pesa, and Crypto payments. Our Main
                clients are Banks and Merchants. For banks and Financial
                companies, we do have services such as Payment machines and
                software, Wallet developments, and Card payment solutions
                including Cards of Credit and debit cards. for the Merchants we
                provide Payment acceptance services with a very minimal fee.
                That includes all the main payment solutions in the world.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white pt-10 md:pt-20">
        <div className="bg-notverydark text-white py-12 md:py-12 mr-16 rounded-tr-extra-large rounded-br-2xl md:mr-20 lg:mr-56">
          <div className="container mx-auto px-4 ">
            <h2 className="text-center text-4xl md:text-5xl font-custom font-bold mb-6 md:mb-8">
              WE ARE,
            </h2>

            {/* Grid layout: single column on mobile, 3 columns on medium screens */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-3 text-center weare:px-44">
              {/* Alipay Agent */}
              <div className="flex flex-col items-center ">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fal_1x.png?alt=media&token=c8b62924-0b29-45fd-856f-af986005a6fa"
                  alt="Alipay Agent"
                  className={`${weimgStyle}`}
                />
                <h3 className={`${weh3Style}`}>Alipay Agent</h3>
                <p className={`${wepStyle}`}>
                  Authorized Alipay agent from the Alibaba Group, China
                </p>
              </div>

              {/* Central Bank Approved */}
              <div className="flex flex-col items-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fcb_1x.png?alt=media&token=45ebafec-77cb-4425-b9c2-9632a1b9a69c"
                  alt="Central Bank Approved"
                  className={`${weimgStyle}`}
                />
                <h3 className={`${weh3Style}`}>Central Bank Approved</h3>
                <p className={`${wepStyle}`}>
                  We are a private limited company with an acquirer license
                  issued by the Central Bank of Sri Lanka
                </p>
              </div>

              {/* The World's First */}
              <div className="flex flex-col items-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fw1_1x.png?alt=media&token=a2618e86-2ab4-48a5-ac09-86529f7fe91a"
                  alt="The World's First"
                  className={`${weimgStyle}`}
                />
                <h3 className={`${weh3Style}`}>The World's First</h3>
                <p className={`${wepStyle}`}>
                  Non-banking organization to have the Alipay acquirer license
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land3;
