import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

// Images (Add your images here)
const images = [
  "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Falipay.jpg?alt=media&token=6a28aaf2-586d-4cff-aab1-b256f265b77c",
  "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fvisa.jpg?alt=media&token=3e06ef08-123b-45b5-b3f7-e0239950bb1f",
  "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2FmasterCard.jpg?alt=media&token=897dfc19-52c1-4a09-8727-e32bd776fe83",
  "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2FwePay.jpg?alt=media&token=3c329f1b-565a-463c-ba12-9611ab46b78f",
  "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2FuniPay.jpg?alt=media&token=e9cab401-45b5-4c5a-8a03-0da2b796eb6d",
  "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Famex.jpg?alt=media&token=fd664515-8f4a-492f-b7d5-a3a41fc3d32c",
];

const data = [
  {
    title: `Cash Advance`,
    img: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fcash_advance.png?alt=media&token=fe0d8a9b-f993-40e9-945d-dcf2bdbd581d",
    description: `Need a quick cash solution? You can get up to the maximum available balance on your credit card just for 3.5% interest.`,
  },
  {
    title: `Payment Links`,
    img: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fpayment_links.png?alt=media&token=48bd369d-69aa-43de-a186-41342e0486f7",
    description: `Unlock the potential of your business with SriPay Payment Links - Expand your reach by connecting with more customers!`,
  },
  {
    title: `Payment Gateways`,
    img: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fpayment_gateway.png?alt=media&token=ef35944b-81e5-4103-b547-9977e1d9c1ad",
    description: `Empower your online business with SriPay Payment Gateway Services. Seamlessly accept payments with low interest rates, enhanced security, and elevate your website's performance.`,
  },
  {
    title: `POS Machines for your shop`,
    img: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2FPOS.png?alt=media&token=1634732e-9479-4fd4-a72f-2c12fd26a302",
    description: `Get a POS machine for your company on rental basis starting from LKR 3500.`,
  },
  {
    title: `QR Codes`,
    img: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fqr_codes.png?alt=media&token=81ffb68c-8c07-445b-9d9e-750aa49169fe",
    description: `Simplify payments with SriPay QR Code Services. Accept funds swiftly and seamlessly from your customers, making transactions effortless for you`,
  },
  {
    title: `Acception Foreign Payments`,
    img: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fforeign_currency.png?alt=media&token=4dde788a-60f7-4275-bb32-fc68ad451ef9",
    description: `Tap into global opportunities with SriPay's Foreign Currency Acceptance Service. Easily receive funds from abroad at competitive exchange rates.`,
  },
  {
    title: `Business Marketing`,
    img: "https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fbusiness_marketing.png?alt=media&token=7f3438d4-4eb6-468f-98ac-2be08600a05e",
    description: `Expand your business horizons with SriPay's Marketing Services. Reach customers in China and Sri Lanka, and watch your business thrive on a global scale.`,
  },
];

// Custom Arrow components
const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-arrow custom-next absolute right-[-30px] sm:right-[-50px] top-1/2 transform -translate-y-1/2 z-10"
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        className="text-blue-500 text-3xl"
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="custom-arrow custom-prev absolute left-[-30px] sm:left-[-50px] top-1/2 transform -translate-y-1/2 z-10"
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
        className="text-blue-500 text-3xl"
      />
    </div>
  );
};

const Land2 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 cards in desktop
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Change slide every 3 seconds (3000ms)
    nextArrow: <NextArrow />, // Custom Next Arrow
    prevArrow: <PrevArrow />, // Custom Prev Arrow
    responsive: [
      {
        breakpoint: 1145, // For screens <= 1024px (tablets)
        settings: {
          slidesToShow: 3, // Show 3 cards
        },
      },
      {
        breakpoint: 768, // For screens <= 768px (smaller tablets or large phones)
        settings: {
          slidesToShow: 1, // Show 2 cards
        },
      },
      {
        breakpoint: 480, // For screens <= 480px (mobile)
        settings: {
          slidesToShow: 1, // Show 1 card in mobile view
        },
      },
    ],
  };

  return (
    <div className="relative w-full bg-">
      <div className="container mx-auto flex justify-center items-center">
        <p className="text-2xl lg:text-3xl lg:max-w-[770px] font-normal font-custom text-center text-black">
          With SriPay, you can choose through a multitude of service providers
          to conduct your transactions!
        </p>
      </div>

      {/* Image Bar */}
      <div className="mt-6">
        {/* Desktop View: All images are displayed */}
        <div className="hidden lg:flex justify-center items-center gap-6">
          {images.map((img, index) => (
            <div key={index} className="w-32 h-32">
              <img
                src={img}
                alt={`logo-${index}`}
                className="w-full h-full object-contain"
              />
            </div>
          ))}
        </div>

        {/* Swiper for Mobile View */}
        <div className="lg:hidden">
          <Swiper
            spaceBetween={10}
            pagination={{
              clickable: true,
              el: ".custom-swiper-pagination", // Target the custom pagination element outside the Swiper component
            }}
            autoplay={{
              delay: 3000, // Set autoplay delay to 3 seconds globally
              disableOnInteraction: false, // Autoplay should continue even after user interaction
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 3, // 2 cards in mobile view
                autoplay: {
                  // Enable autoplay only for mobile views
                  delay: 3000, // Set autoplay delay to 3 seconds
                  disableOnInteraction: false, // Autoplay should continue even after user interaction
                },
              },
            }}
          >
            {images.map((img, index) => (
              <SwiperSlide key={index}>
                <div className="flex justify-center">
                  {/* Smaller images in mobile view */}
                  <div className="w-24 h-24">
                    <img
                      src={img}
                      alt={`logo-${index}`}
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Custom pagination, only visible in mobile view */}
        <div className="custom-swiper-pagination mt-8 flex justify-center lg:hidden"></div>
      </div>

      <div className="w-5/6 m-auto relative">
        <div className="mt-3 ">
          <Slider {...settings}>
            {data.map((d, index) => (
              <div
                key={index}
                className="bg-white-blue shadow-md rounded-3xl  h-[430px]  sm:h-[440px] my-10 flex flex-col  "
              >
                <div className="h-40 flex items-center">
                  <img
                    src={d.img}
                    alt=""
                    className="h-full rounded-t-2xl object-cover w-full"
                  />
                </div>

                <div className="flex-grow flex flex-col justify-between sm:h-[240px] h-[220px] ">
                  <div>
                    <p className="text-xl font-semibold font-custom px-2 h-14 text-center">
                      {d.title}
                    </p>
                    <p className="text-center text-sm font-light font-custom px-4 text-gray-700">
                      {d.description}
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <button className="bg-white-blue  md:ml-0 text-light-blue text-base font-custom font-normal hover:text-white px-3 py-1 rounded-2xl hover:bg-dark-blue border border-dark-blue duration-500 self-center mt-4">
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="w-full bg-notverydark">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fbar2%201.png?alt=media&token=8b53cca8-128b-42c0-801c-cc69babdd872"
          alt="Full width bar image"
          className="w-full h-auto "
        />
      </div>
    </div>
  );
};

export default Land2;
