import React from "react";

const Footer = () => {
  return (
    <footer className="bg-notverydark text-white p-6 font-custom font-light">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Contact Information */}
        <div>
          <h2 className="text-2xl font-bold">CONTACT US</h2>
          <p className="mt-6 font-semibold">
            Boswin Consultancy and Investment Private Limited
          </p>
          <p className="mt-4 font-normal text-light-blue">
            <span className="inline-block font-light text-white ">
              Address:
            </span>{" "}
            12, 2nd Lane, Beddagana Road, Pita Kotte, Sri Lanka.
          </p>
          <p className="mt-2 font-normal text-light-blue">
            <span className="inline-block font-light text-white">
              Phone Number:
            </span>{" "}
            <a href="tel:+94767035500" className="hover:underline">
              +9476 703 5500
            </a>
          </p>
          <p className="mt-2 font-normal text-light-blue">
            <span className="inline-block font-light text-white">Email:</span>{" "}
            <a href="mailto:contact@sripay.lk" className="hover:underline">
              contact@sripay.lk
            </a>
          </p>
        </div>

        {/* POS Systems */}
        <div>
          <h2 className="text-2xl font-bold">POS SYSTEMS</h2>
          <ul className="mt-6 space-y-2">
            <li>SUNMI</li>
            <li>FALCORRA</li>
          </ul>
        </div>

        {/* Gateway Solutions */}
        <div>
          <h2 className="text-2xl font-bold">GATEWAY SOLUTIONS</h2>
          <ul className="mt-6 space-y-2">
            <li>Alipay</li>
            <li>WeChat Pay</li>
            <li>Visa Master</li>
          </ul>
        </div>

        {/* Top Link */}
        <div>
          <h2 className="text-2xl font-bold">TOP LINK</h2>
          <ul className="mt-6 space-y-2">
            <li>IT Solutions</li>
            <li>Cash Advance</li>
            <li>Privacy Policy</li>
            <li>Terms & Conditions</li>
          </ul>
        </div>
      </div>

      {/* Copyright or additional info */}
      <div className="mt-8 text-center text-sm">
        <p>&copy; 2024 Boswin Consultancy and Investment Private Limited</p>
      </div>
    </footer>
  );
};

export default Footer;
