import React, { useState } from "react";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/solid";

const Header = () => {
 let Links = [
 /*  { name: "Payment Services", link: "/" },
    { name: "Alipay Plus", link: "/" },
    { name: "Cash Advance", link: "/" },
    { name: "POS", link: "/" },
    { name: "Travel Services", link: "/" },
    { name: "Fintech", link: "/" },
    { name: "About", link: "/About" },
    { name: "FAQ", link: "/" }, */
    { name: "   ", link: "/" },
  ];

  let [open, setOpen] = useState(false);

  return (
    <div className="shadow-md w-full fixed top-0 left-0 z-50 bg-white">
      <div className="custom:flex items-center justify-between bg-white py-4 custom:px-3 px-7">
        {/* logo section */}
        <img
          src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fplus.png?alt=media&token=8ce9a8dd-bc39-428f-a390-0fe92ad67c13"
          alt="Sri Pay"
          className="w-15 h-10"
        />

        {/* Menu icon */}
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-6 cursor-pointer custom:hidden w-7 h-7"
        >
          {open ? <XMarkIcon /> : <Bars3BottomRightIcon />}
        </div>

        {/* link items */}
        <ul
          className={`custom:flex custom:items-center custom:pb-0 pb-5  absolute custom:static bg-white custom:z-auto z-[-1] left-0 w-full custom:w-auto custom:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-12" : "top-[-490px]"
          }`}
        >
            
          {Links.map((link) => (
            <li
              key={link.name}
              className="custom:ml-7 custom:my-0 my-7 font-custom font-bold"
            >
              <a
                href={link.link}
                className="text-light-blue hover:text-dark-blue duration-500"
              >
                {link.name}
              </a>
            </li>
          ))}
          
          {/* First Button */}
          <a href="https://dashboard.sripay.lk/auth/login">
            <button className="btn bg-white custom:ml-10 text-light-blue text-base font-custom font-normal hover:text-white duration-500 px-3 py-1 rounded-2xl hover:bg-dark-blue border border-dark-blue">
              LOGIN
            </button>
          </a>

          {/* Pipe Separator */}
          <span className="text-gray-400 mx-3 custom:mx-2">|</span>

          {/* Second Button */}
          <a href="https://dashboard.sripay.lk/auth/register">
            <button className="btn bg-white custom:ml-0 text-light-blue text-base font-custom font-normal hover:text-white px-3 py-1 rounded-2xl hover:bg-dark-blue border border-dark-blue duration-500">
              SIGN UP
            </button>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default Header;
