import React from "react";

const Land5 = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center py-20 md:pt-20 ">
        <h2 className="text-5xl font-custom font-bold text-notverydark text-center uppercase tracking-wide">
          FINTECH SOLUTION FOR YOUR COMPANY
        </h2>
      </div>

      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 items-start">
        {/* Left Column: Image */}
        <div className="w-full flex flex-col justify-center my-auto ">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FAnimation%2FAlipay-ConnectingGlobalBrandswithMobileConsumers-ezgif.com-optimize%20(1).gif?alt=media&token=405bd4b3-d3ab-40b9-a4f9-9ac440df2559" // replace with your image path
            alt="POS Machine with customer"
            className="rounded-lg w-full"
          />
        </div>

        {/* Right Column: Text Section */}
        <div className=" w-full ">
          <p className="px-8 lg:px-0 mb-4 md:text-center lg:text-start font-custom text-gray-500">
            Elevate your business with SriPay's FinTech Solutions. Our
            cutting-edge POS machines and software redefine convenience, making
            transactions seamless and secure. Experience financial innovation
            tailored to your company’s needs, unlocking a new era of efficiency
            and growth in the digital landscape.
          </p>
          <div className="bg-light-blue bg-opacity-40 p-10 lg:p-10 md:p-7 md:rounded-t-large md:rounded-br-large md:rounded-bl-extra-large">
            <h3 className="text-2xl lg:text-2xl md:text-xl font-semibold font-custom text-notverydark mb-2">
              MACHINES
            </h3>
            <p className="mb-4 text-gray-500 text-md lg:text-md md:text-sm font-light font-custom">
              We provide the world's first-class top brand Financial products
              and equipment to our clients at the most reasonable price embedded
              with the payment systems.
            </p>

            <h3 className="text-2xl lg:text-2xl md:text-xl font-semibold font-custom text-notverydark mb-2">
              SOFTWARE SOLUTIONS
            </h3>
            <p className="mb-4 text-gray-500 text-md lg:text-md md:text-sm font-light font-custom">
              SriPay always provides software solutions such as restaurant
              management software, University management software, API and
              plugins for apps, Supermarket management software, etc.
            </p>

            <h3 className="text-2xl lg:text-2xl md:text-xl font-semibold font-custom text-notverydark mb-2">
              CUSTOM MADE SOLUTIONS
            </h3>
            <p className="mb-4 text-gray-500 text-md lg:text-md md:text-sm font-light font-custom">
              We provide payment solutions for customers based on their
              requirements, and further, we give them the Payment solutions from
              all over the world and Settlement software.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center py-10 md:pt-20 ">
        <h2 className="text-5xl font-custom font-light text-light-blue text-center uppercase tracking-wide">
          Our{" "}
          <span className="text-notverydark font-bold font-custom">
            PARTNERS
          </span>
        </h2>
      </div>
      <div className="flex  items-center justify-center">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FBrand%2Fdfcc_bank_logo_1x.png?alt=media&token=8882b8b2-6f8f-4a3b-aa23-241cc042e094" // replace with your image path
          alt="DFCC bank"
          className=" w-56"
        />
      </div>

      <div className="py-20 px-3 ">
        <div className="max-w-xl mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-6xl">
          <div className="md:flex bg-white-blue w-full h-full">
            {/* Left side content */}
            <div className="p-16 md:w-2/3 flex justify-center items-center h-full">
              <div className="flex flex-col justify-center items-center h-full">
                <h2 className="text-4xl font-custom font-bold text-blue-500 text-center uppercase tracking-wide">
                  WELCOME TO Our{" "}
                  <span className="text-notverydark font-bold font-custom">
                    COMMUNITY
                  </span>
                </h2>
                <div className="lg:pt-10">
                  <a
                    href="https://chat.whatsapp.com/G5QXHQOcfiK05vVDLQOGiM"
                    className="inline-block mt-4 font-custom bg-notverydark text-white px-10 py-3 rounded-full text-lg leading-tight font-medium hover:bg-blue-600"
                  >
                    Join Now
                  </a>
                </div>
              </div>
            </div>

            {/* Right side QR section */}
            <div className="flex items-center justify-center md:w-1/3 p-4 py-10">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/sripay-plus.appspot.com/o/Assets%2FImages%2Fqr.png?alt=media&token=8dccc0f6-9a4e-4d1b-9f74-6c2436410bd9"
                alt="QR Code"
                className="object-contain h-72 w-72"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Land5;
