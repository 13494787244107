import React from 'react';

const About = () => {
  return (
    <div className="w-full bg-white pt-16">
      <h1>About Page</h1>
      <p>Welcome to the About Page!</p>
    </div>
  );
};

export default About;
